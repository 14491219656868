import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { object, string, array, boolean } from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { TooltipIcon, Table, TableHeader, Card, Button, Dropdown, Checkbox, Input, MessageBar, LoadingAnimation, Modal } from 'app/components';
import { createRefundRequest, reset as resetOrderData } from 'app/store/actions/order';
import { refundAnOrderLoadingSelector, refundAnOrderErrorsSelector } from 'app/store/selectors/order';
import { formatCurrency } from 'app/utils';
import './index.scss';

const RefundView = props => {
  const { orderId, orderDetails, orderDetailsErrors } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isMasterChecked, setIsMasterChecked] = useState(true);
  const [confirmRefundModal, showConfirmRefundModal] = useState(null);

  const refundLoading = useSelector(refundAnOrderLoadingSelector);
  const refundErrors = useSelector(refundAnOrderErrorsSelector);

  const handleMasterCheckboxChange = (checked, setFieldValue, values) => {
    setIsMasterChecked(checked);
    values.orderItems.forEach((_, index) => {
      setFieldValue(`orderItems[${index}].check`, checked);
    });
  };

  const handleIndividualCheckboxChange = (index, checked, values, setFieldValue) => {
    setFieldValue(`orderItems[${index}].check`, checked);

    const updatedItems = values.orderItems.map((item, i) =>
      i === index ? { ...item, check: checked } : item
    );

    const allChecked = updatedItems.every(item => item.check);

    setIsMasterChecked(allChecked);
  };

  const handleBlur = (index, fieldName, value, setFieldValue) => {
    const numericValue = parseFloat(value);
    if (!isNaN(numericValue)) {
      const formattedValue = numericValue.toFixed(2);
      setFieldValue(`orderItems[${index}].${fieldName}`, formattedValue);
    } else {
      setFieldValue(`orderItems[${index}].${fieldName}`, '');
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          orderItems: orderDetails?.items?.map(item => {
            // calculate refundItemAmount and refundShippingAmount
            const refundItemAmount = item.refundData?.refunds
              ?.filter(refund => !refund.isShippingRefund)
              ?.reduce((sum, refund) => sum + refund.amount, 0) || 0;

            const refundShippingAmount = item.refundData?.refunds
              ?.filter(refund => refund.isShippingRefund)
              ?.reduce((sum, refund) => sum + refund.amount, 0) || 0;

            return {
              id: item.id,
              shortId: item.shortId,
              sku: item.sku,
              check: true,
              refundReason: '',
              customerPrice: item.customerPrice?.amount ? item.customerPrice.amount.toFixed(2) : '0.00',  
              customerShippingPrice: item.customerShippingPrice?.amount ? item.customerShippingPrice.amount.toFixed(2) : '0.00',
              remainingPriceBalance: item.refundData?.remainingPriceBalance ? item.refundData.remainingPriceBalance.toFixed(2) : '0.00',
              remainingShippingBalance: item.refundData?.remainingShippingBalance ? item.refundData.remainingShippingBalance.toFixed(2) : '0.00',
              itemAmount: item.refundData?.remainingPriceBalance ? item.refundData.remainingPriceBalance.toFixed(2) : '0.00',
              shippingAmount: item.refundData?.remainingShippingBalance ? item.refundData.remainingShippingBalance.toFixed(2) : '0.00',
              refundItemAmount: refundItemAmount.toFixed(2),
              refundShippingAmount: refundShippingAmount.toFixed(2),
            };
          }),
        }}
        validationSchema={object().shape({
          orderItems: array().of(
            object().shape({
              check: boolean(),
              refundReason: string().when('check', {
                is: (check) => check,
                then: () => string().required('Refund reason is required'),
                otherwise: () => string().notRequired(),
              }),
              itemAmount: string().when('check', {
                is: (check) => check,
                then: () => string()
                  .matches(/^\d+(\.\d{1,2})?$/, 'Invalid dollar amount')
                  .test('item-or-shipping-required-itemAmount', 'Either Item Amount or Shipping Amount is required, but not both can be zero', function (value, context) {
                    const { check, shippingAmount } = context.parent;
                    const itemAmountValid = parseFloat(value) > 0;
                    const shippingAmountValid = parseFloat(shippingAmount) > 0;
        
                    if (check && !itemAmountValid && !shippingAmountValid) {
                      return this.createError({ path: `${this.path}`, message: 'Either Item Amount or Shipping Amount is required, but not both can be zero' });
                    }
                    return true;
                  })
                  .test('max-item-amount', 'Item Amount cannot exceed remaining price balance', function (value, context) {
                    const remainingPriceBalance = parseFloat(context.parent.remainingPriceBalance); 
                    const itemAmount = parseFloat(value); 
                    
                    if (itemAmount > remainingPriceBalance) {
                      return this.createError({ path: `${this.path}`, message: 'Item Amount cannot exceed remaining price balance' });
                    }
                    return true;
                  }),
                otherwise: () => string().notRequired(),
              }),
              shippingAmount: string().when('check', {
                is: (check) => check,
                then: () => string()
                  .matches(/^\d+(\.\d{1,2})?$/, 'Invalid dollar amount')
                  .test('item-or-shipping-required-shippingAmount', 'Either Item Amount or Shipping Amount is required, but not both can be zero', function (value, context) {
                    const { check, itemAmount } = context.parent;
                    const itemAmountValid = parseFloat(itemAmount) > 0;
                    const shippingAmountValid = parseFloat(value) > 0;
        
                    if (check && !itemAmountValid && !shippingAmountValid) {
                      return this.createError({ path: `${this.path}`, message: 'Either Item Amount or Shipping Amount is required, but not both can be zero' });
                    }
                    return true;
                  })
                  .test('max-shipping-amount', 'Shipping Amount cannot exceed remaining shipping balance', function (value, context) {
                    const remainingShippingBalance = parseFloat(context.parent.remainingShippingBalance); 
                    const shippingAmount = parseFloat(value); 
        
                    if (shippingAmount > remainingShippingBalance) {
                      return this.createError({ path: `${this.path}`, message: 'Shipping Amount cannot exceed remaining shipping balance' });
                    }
                    return true;
                  }),
                otherwise: () => string().notRequired(),
              }),
            })
          ),
        })}
        onSubmit={(values) => {
          const refunds = [];
        
          values.orderItems.forEach((item) => {
            // only include items that were checked (which means they are to be refunded)
            if (item.check) {
              // check if remainingPriceBalance is present and greater than 0
              if (item.itemAmount && parseFloat(item.itemAmount) > 0) {
                refunds.push({
                  itemId: item.id,
                  isShippingRefund: false,
                  amount: parseFloat(item.itemAmount),
                  reason: item.refundReason,
                });
              }
        
              // check if remainingShippingBalance is present and greater than 0
              if (item.shippingAmount && parseFloat(item.shippingAmount) > 0) {
                refunds.push({
                  itemId: item.id,
                  isShippingRefund: true,
                  amount: parseFloat(item.shippingAmount),
                  reason: item.refundReason,
                });
              }
            }
          });
        
          const data = {
            orderId: orderDetails.id,
            refunds,
          };

          const totalRefundAmount = values.orderItems?.reduce((total, item) => {
            if (item.check) {
              const itemAmount = parseFloat(item.itemAmount) || 0;
              const shippingAmount = parseFloat(item.shippingAmount) || 0;
              return total + itemAmount + shippingAmount;
            }
            return total;
          }, 0);

          showConfirmRefundModal({data, totalRefundAmount, currency: orderDetails.customerTotal?.currency});
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateForm,
          submitCount,
        }) => {
          const selectedItemCount = values.orderItems?.filter(item => item.check).length || 0;
        
          // calculate the total refund amount
          const totalRefundAmount = values.orderItems?.reduce((total, item) => {
            if (item.check) {
              const itemAmount = parseFloat(item.itemAmount) || 0;
              const shippingAmount = parseFloat(item.shippingAmount) || 0;
              return total + itemAmount + shippingAmount;
            }
            return total;
          }, 0);

          // calculate the recalculated order total
          const recalculatedOrderTotal = orderDetails?.customerTotal?.amount 
            ? orderDetails?.customerTotal.amount - totalRefundAmount 
            : 0;

          // calculate the total number of items with errors (just count the number of objects minus the null values)
          const totalItemsWithErrors = Object.values(errors.orderItems || {}).filter(Boolean).length;

          return (
            <Card className="refund-view">
              {refundLoading && <LoadingAnimation />}
              <Card.Header>
                Items to Refund
                <div className="header-buttons">
                  <Button
                    size="small"
                    variant="secondary"
                    label="Cancel"
                    onClick={() => navigate(`/orders/${orderId}`)}
                  />
                  <Button
                    size="small"
                    label={`Refund ${formatCurrency(orderDetails.customerTotal?.currency, totalRefundAmount)}`}
                    onClick={() => {
                      validateForm().then(() => {
                        handleSubmit();
                      });
                    }}
                    disabled={selectedItemCount === 0 || orderDetailsErrors}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                {orderDetails && !orderDetailsErrors && (
                  <>
                    <div>
                      <Table size="small" highlightRows={false} className="costs-table">
                        <tbody className="table-body">
                          <tr className="total-row">
                            <td>Order Subtotal</td>
                            <td>
                              {orderDetails.customerItemTotal?.amount != null && orderDetails.customerItemTotal?.currency
                                ? formatCurrency(orderDetails.customerItemTotal.currency, orderDetails.customerItemTotal.amount)
                                : '-'}
                            </td>
                          </tr>
                          <tr className="total-row">
                            <td>Total Shipping</td>
                            <td>
                              {orderDetails.customerTotalShipping?.amount != null && orderDetails.customerTotalShipping?.currency
                                ? formatCurrency(orderDetails.customerTotalShipping.currency, orderDetails.customerTotalShipping.amount)
                                : '-'}
                            </td>
                          </tr>
                          <tr className="total-row">
                            <td>Total Discounts And Promotions</td>
                            <td>
                              {orderDetails.customerTotalDiscountAndPromotions?.amount != null && orderDetails.customerTotalDiscountAndPromotions?.currency
                                ? formatCurrency(orderDetails.customerTotalDiscountAndPromotions.currency, orderDetails.customerTotalDiscountAndPromotions.amount)
                                : '-'}
                            </td>
                          </tr>
                          <tr className="total-row extra-border">
                            <td>Total Tax</td>
                            <td>
                              {orderDetails.customerTotalTaxes?.amount != null && orderDetails.customerTotalTaxes?.currency
                                ? formatCurrency(orderDetails.customerTotalTaxes.currency, orderDetails.customerTotalTaxes.amount)
                                : '-'}
                            </td>
                          </tr>
                          <tr className="total-row">
                            <td>Order Total</td>
                            <td>
                              {orderDetails.customerTotal?.amount != null && orderDetails.customerTotal?.currency
                                ? formatCurrency(orderDetails.customerTotal.currency, orderDetails.customerTotal.amount)
                                : '-'}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                      {submitCount > 0 && totalItemsWithErrors > 0 && (
                        <MessageBar color="red">
                          {`Please correct the ${totalItemsWithErrors} item${totalItemsWithErrors > 1 ? 's' : ''} with errors below`}
                        </MessageBar>
                      )}
                      {refundErrors?.errors?.[0]?.errorMessage && (
                        <MessageBar color="red">
                          {refundErrors.errors[0].errorMessage}
                        </MessageBar>
                      )}
                    </div>
                    <Table size="small" highlightRows={false} className="items-table">
                      <TableHeader
                        options={[
                          { id: "check", isMasterCheckbox: true, label: "" },
                          { id: 'product', label: 'Product' },
                          { id: 'itemSku', label: 'Item SKU' },
                          { id: 'remainingPriceBalance', label: 'Item Amount' },
                          { id: 'remainingShippingBalance', label: 'Shipping Amount' },
                          { id: 'refundReason', label: 'Refund Reason' },
                        ]}
                        isMasterChecked={isMasterChecked}
                        onMasterCheckboxChange={(e) => handleMasterCheckboxChange(e, setFieldValue, values)}
                      />
                      <tbody className="table-body">
                        {values.orderItems.map((item, index) => (
                          <tr
                            className={`details-row ${item.check ? 'selected' : ''}`}
                            key={item.id}
                          >
                            <td>
                              <Checkbox
                                size="medium"
                                checked={item.check}
                                onChange={(e) => handleIndividualCheckboxChange(index, e, values, setFieldValue)}
                              />
                            </td>
                            <td className="details-sku">{`Item ${item.shortId}`}</td>
                            <td>{item.sku}</td>
                            <td>
                              <Input
                                name={`orderItems[${index}].itemAmount`}
                                value={item.check ? item.itemAmount : ''}
                                onChange={handleChange}
                                onBlur={() => handleBlur(index, 'itemAmount', item.itemAmount, setFieldValue)}
                                placeholder={item.check ? 'Item Refund Amount' : ''}
                                errorMessage={submitCount > 0 && errors.orderItems?.[index]?.itemAmount}
                                showErrorMessages={false}
                                readOnly={!item.check} 
                                type="number"
                                step="0.01" 
                                min="0" 
                                max={item.remainingPriceBalance}
                              />
                              <div className="refundable-text">
                                Refundable: {item.remainingPriceBalance != null && orderDetails.customerTotal?.currency
                                  ? formatCurrency(orderDetails.customerTotal.currency, item.remainingPriceBalance)
                                  : '-'}
                                {item.refundItemAmount > 0 && (
                                  <TooltipIcon
                                    htmlContent={`${formatCurrency(orderDetails.customerTotal.currency, item.refundItemAmount)} Previously Refunded.<br>See Refund History tab for additional details`}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <Input
                                name={`orderItems[${index}].shippingAmount`}
                                value={item.check ? item.shippingAmount : ''}
                                onChange={handleChange}
                                onBlur={() => handleBlur(index, 'shippingAmount', item.shippingAmount, setFieldValue)}
                                placeholder={values.orderItems[index].check ? 'Shipping Refund Amount' : ''}
                                errorMessage={submitCount > 0 && errors.orderItems?.[index]?.shippingAmount}
                                showErrorMessages={false}
                                readOnly={!item.check}
                                type="number" 
                                step="0.01" 
                                min="0" 
                                max={item.remainingShippingBalance}
                              />
                              <div className="refundable-text">
                                Refundable: {item.remainingShippingBalance != null && orderDetails.customerTotal?.currency
                                  ? formatCurrency(orderDetails.customerTotal.currency, item.remainingShippingBalance)
                                  : '-'}
                                {item.refundShippingAmount > 0 && (
                                  <TooltipIcon
                                    htmlContent={`${formatCurrency(orderDetails.customerTotal.currency, item.refundShippingAmount)} Previously Refunded.<br>See Refund History tab for additional details`}
                                  />
                                )}
                              </div>
                            </td>
                            <td>
                              <Dropdown
                                name={`orderItems[${index}].refundReason`}
                                size="small"
                                placeholder={item.check ? 'Select Reason' : ''}
                                value={item.check ? item.refundReason : ''}
                                onChange={handleChange}
                                options={[
                                  { value: 'Cancel', label: 'Cancel' },
                                  { value: 'OOSLA', label: 'OOSLA' },
                                  { value: 'Quality Issue', label: 'Quality Issue' },
                                  { value: 'Image Issue', label: 'Image Issue' },
                                  { value: 'Shipping issue', label: 'Shipping issue' },
                                ]}
                                errorMessage={submitCount > 0 && errors.orderItems?.[index]?.refundReason}
                                showErrorMessages={false}
                                allowClear={true}
                                readOnly={!item.check} 
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Table size="small" highlightRows={false} className="summary-table">
                      <tbody className="table-body">
                        <tr className="total-row extra-border">
                          <td>Total Order Refund</td>
                          <td>{formatCurrency(orderDetails.customerTotal?.currency, totalRefundAmount)}</td>
                        </tr>
                        <tr className="total-row">
                          <td>Recalculated Order Total</td>
                          <td>{formatCurrency(orderDetails.customerTotal?.currency, recalculatedOrderTotal)}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                )}
                {orderDetailsErrors && (
                  <MessageBar color="yellow">
                    An error occurred while fetching order data
                  </MessageBar>
                )}
              </Card.Body>
            </Card>
          );
        }}
      </Formik>
      {confirmRefundModal && (
        <Modal
          title="Are you sure you want to process this refund?"
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={() => { showConfirmRefundModal(null) }}
          primaryButtonLabel="Confirm Refund"
          primaryButtonVariant="primary"
          primaryButtonDestructive={true}
          primaryButtonOnClick={() => {
            dispatch(createRefundRequest({data: confirmRefundModal.data, cb: () => {
              dispatch(resetOrderData());
              navigate(`/orders/${orderId}`);
            }}));
            showConfirmRefundModal(null);
          }}
          onClose={() => showConfirmRefundModal(null)}
        >
          <div>You are about to <b>Refund {formatCurrency(confirmRefundModal.currency, confirmRefundModal.totalRefundAmount)}</b> for this order.  Refunding this order cannot be undone.</div>
          <div>
            <li>If you proceed the refund will be processed immediately</li>
            <li>Make sure you&apos;ve reviewed all refund details before confirming.</li>
          </div>
        </Modal>
      )}
    </>
  );
}

export default RefundView;
