import { select, put, takeEvery, call, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  getVendors,
  getVendorsSuccess,
  getVendorsFailure,
  getAllVendors,
  getAllVendorsSuccess,
  getAllVendorsFailure,
  getVendorsBulk,
  getVendorsBulkSuccess,
  getVendorsBulkFailure,
  getVendorDetails,
  getVendorDetailsSuccess,
  getVendorDetailsFailure,
  createVendor,
  createVendorSuccess,
  createVendorFailure,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailure,
  getFacilityDetails,
  getFacilityDetailsSuccess,
  getFacilityDetailsFailure,
  getProductsByVendor,
  getProductsByVendorSuccess,
  getProductsByVendorFailure,
  getAllProductsByVendor,
  getAllProductsByVendorSuccess,
  getAllProductsByVendorFailure,
  addProductsToVendor,
  addProductsToVendorSuccess,
  addProductsToVendorFailure,
  updateVendorProducts,
  updateVendorProductsSuccess,
  updateVendorProductsFailure,
  updateFacilityProduct,
  updateFacilityProductSuccess,
  updateFacilityProductFailure,
  addFacility,
  addFacilitySuccess,
  addFacilityFailure,
  updateFacility,
  updateFacilitySuccess,
  updateFacilityFailure,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  getVendorAuditInfo,
  getVendorAuditInfoSuccess,
  getVendorAuditInfoFailure,
  getFacilityAuditInfo,
  getFacilityAuditInfoSuccess,
  getFacilityAuditInfoFailure,
  getVendorProductAuditInfo,
  getVendorProductAuditInfoSuccess,
  getVendorProductAuditInfoFailure,
  getAllValueAddedServices,
  getAllValueAddedServicesSuccess,
  getAllValueAddedServicesFailure,
  bulkGetFacilities,
  bulkGetFacilitiesSuccess,
  bulkGetFacilitiesFailure,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailure,
  searchViewAsVendors,
  searchViewAsVendorsSuccess,
  searchViewAsVendorsFailure,
  getVendorConfig,
  getVendorConfigSuccess,
  getVendorConfigFailure,
  updateVendorConfig,
  updateVendorConfigSuccess,
  updateVendorConfigFailure,
  createVendorConfig,
  createVendorConfigSuccess,
  createVendorConfigFailure
} from "app/store/actions/vendor"
import VendorServices from 'app/services/vendorServices'
import ConfigServices from 'app/services/configServices';

const selectVendorSchemaVersion = state => state.vendor.vendorConfig.data.schemaVersion;

function* fetchVendors(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendors], action.payload);
    yield put(getVendorsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getVendorsFailure(error));
  }
}

function* fetchAllVendors(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getAllVendors], action.payload);
    yield put(getAllVendorsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getAllVendorsFailure(error));
  }
}


function* fetchVendorsBulk(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendorsBulk], action.payload);
    yield put(getVendorsBulkSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(getVendorsBulkFailure(error));
  }
}

function* fetchVendorDetails(action) {
  const { vendorId, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getVendorDetails], vendorId);
    yield put(getVendorDetailsSuccess(resp));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getVendorDetailsFailure(error));
  }
}

function* fetchProductsByVendor(action) {
  const { vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir, showBy, vendorIds } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getProductsByVendor], vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir, showBy, vendorIds);
    yield put(getProductsByVendorSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getProductsByVendorFailure(error));
  }
}

// the following function gets ALL the products for a vendor.  It can only get 250 at a time so it needs to be called multiple times
function* fetchAllProductsByVendor(action) {
  const { vendorId, searchString, cb } = action.payload;

  let currentPage = 1;
  const pageSize = 250;
  let totalProducts = 0;
  let products = [];

  try {
    // fetch the first page to get the total number of products
    const initialResp = yield call(VendorServices.getProductsByVendor, vendorId, null, searchString, currentPage, pageSize);
    totalProducts = initialResp.total;
    products = initialResp.vendorProducts;

    // calculate the total number of pages needed to fetch all products
    const totalPages = Math.ceil(totalProducts / pageSize);

    // fetch remaining pages
    for (currentPage = 2; currentPage <= totalPages; currentPage++) {
      const resp = yield call(VendorServices.getProductsByVendor, vendorId, null, searchString, currentPage, pageSize);
      products = products.concat(resp.vendorProducts);
    }
    // store the products
    yield put(getAllProductsByVendorSuccess(products));
    // return the products
    if (cb) cb(products);
  } catch (error) {
    console.error('error', error);
    yield put(getAllProductsByVendorFailure(error));
    toast.error("Product Download Failed", {
      theme: 'colored',
    });
  }
}

function* doCreateVendor(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.createVendor], data);
    yield put(createVendorSuccess(resp));
    if (cb) cb(resp.vendorId);
    toast.success("New Vendor Successfully Created", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(createVendorFailure(error));
    toast.error("Create Vendor Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateVendor(action) {
  const { vendorId, data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.updateVendor], vendorId, data);
    yield put(updateVendorSuccess(data));
    if (cb) cb(resp);
    toast.success("Vendor Successfully Updated", {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(updateVendorFailure(error));
    toast.error("Update Vendor Failed", {
      theme: 'colored',
    });
  }
}

function* fetchFacilityDetails(action) {
  const { vendorId, facilityId, cb, cbError } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getFacilityDetails], vendorId, facilityId);
    yield put(getFacilityDetailsSuccess(resp));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getFacilityDetailsFailure(error));
    if (cbError) cbError(error);
  }
}

function* doAddProductsToVendor(action) {
  const { data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.addProductsToVendor], data);

    let successCount = 0;
    let failureCount = 0;

    if (resp.bulk && Array.isArray(resp.bulk)) {
      resp.bulk.forEach(item => {
        if (item.status === 200) {
          successCount++;
        } else {
          failureCount++;
        }
      });
    }

    if (failureCount > 0) {
      toast.error(`${failureCount} Product${failureCount > 1 ? 's' : ''} Failed to Add`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${successCount} Product${successCount > 1 ? 's' : ''} Successfully Added`, {
        theme: 'colored',
      });
    }

    // we call the success action even if there are failures because it just removes the loading spinner
    yield put(addProductsToVendorSuccess());
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(addProductsToVendorFailure(error));
  }
}

function* doUpdateVendorProducts(action) {
  const { data, searchParams, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.updateVendorProducts], data);

    let successCount = 0;
    let failureCount = 0;

    if (resp.bulk && Array.isArray(resp.bulk)) {
      resp.bulk.forEach(item => {
        if (item.status === 200) {
          successCount++;
        } else {
          failureCount++;
        }
      });
    }

    if (failureCount > 0) {
      toast.error(`${failureCount} Product${failureCount > 1 ? 's' : ''} Failed to Update`, {
        theme: 'colored',
      });
    } else {
      toast.success(`${successCount} Product${successCount > 1 ? 's' : ''} Successfully Updated`, {
        theme: 'colored',
      });
    }

    // we call the success action even if there are failures because it just removes the loading spinner
    yield put(updateVendorProductsSuccess());

    const { vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir } = searchParams;
    const resp2 = yield call([VendorServices, VendorServices.getProductsByVendor], vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir);
    yield put(getProductsByVendorSuccess(resp2));

    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(updateVendorProductsFailure(error));
    toast.error("Update Products Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateFacilityProduct(action) {
  const { productId, facilityId, data, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.updateFacilityProduct], data, productId, facilityId);
    yield put(updateFacilityProductSuccess({ resp }));
    if (cb) cb(resp);
    toast.success("Product Successfully Updated", {
      theme: 'colored',
    });
  }
  catch (error) {
    console.error('error', error);
    yield put(updateFacilityProductFailure(error));
    toast.error("Update Product Failed", {
      theme: 'colored',
    });
  }
}


function* doAddFacility(action) {
  const { vendorId, data, cb } = action.payload;

  try {
    const resp = yield call([VendorServices, VendorServices.addFacility], vendorId, data);
    yield put(addFacilitySuccess({ data, resp }));
    if (cb) cb(resp);
    toast.success(`Facility Successfully Added`, {
      theme: 'colored',
    });
  } catch (error) {
    console.error('error', error);
    yield put(addFacilityFailure(error));
    toast.error("Adding Facility Failed", {
      theme: 'colored',
    });
  }
}

function* doUpdateFacility(action) {
  const { vendorId, facilityId, data, cb } = action.payload;

  try {
    const resp = yield call([VendorServices, VendorServices.updateFacility], vendorId, facilityId, data);
    const resp2 = yield call([VendorServices, VendorServices.getVendorDetails], vendorId);
    yield put(getVendorDetailsSuccess(resp2));
    toast.success(`Facility Successfully Updated`, {
      theme: 'colored',
    });
    yield put(updateFacilitySuccess({ resp }));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(updateFacilityFailure(error));
    toast.error("Facility Update Failed", {
      theme: 'colored',
    });
  }
}

function* fetchAuditLogs(action) {
  const { vendorId } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getAuditLogs], vendorId);
    yield put(getAuditLogsSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAuditLogsFailure(error));
  }
}

function* fetchVendorAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getAuditInfo], auditUrl);
    yield put(getVendorAuditInfoSuccess(resp));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getVendorAuditInfoFailure(error));
    toast.error("Failed to get vendor audit info", {
      theme: 'colored',
    })
  }
}

function* fetchFacilityAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getAuditInfo], auditUrl);
    yield put(getFacilityAuditInfoSuccess(resp));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getFacilityAuditInfoFailure(error));
    toast.error("Failed to get facility audit info", {
      theme: 'colored',
    })
  }
}

function* fetchVendorProductAuditInfo(action) {
  const { auditUrl, cb } = action.payload;
  try {
    const resp = yield call([VendorServices, VendorServices.getAuditInfo], auditUrl);
    yield put(getVendorProductAuditInfoSuccess(resp));
    if (cb) cb(resp);
  } catch (error) {
    console.error('error', error);
    yield put(getVendorProductAuditInfoFailure(error));
    toast.error("Failed to get vendor product audit info", {
      theme: 'colored',
    })
  }
}

function* fetchAllValueAddedServices() {
  try {
    const resp = yield call([VendorServices, VendorServices.getAllValueAddedServices]);
    yield put(getAllValueAddedServicesSuccess(resp));
  } catch (error) {
    console.error('error', error);
    yield put(getAllValueAddedServicesFailure(error));
  }
}

function* bulkGetFacilitiesHandler(action) {
  const facilityIds = action.payload;
  try {
    const data = yield call([VendorServices, VendorServices.bulkGetFacilities], facilityIds);
    yield put(bulkGetFacilitiesSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(bulkGetFacilitiesFailure(error));
  }
}

function* searchVendorsHandler(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendors], action.payload);
    yield put(searchVendorsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(searchVendorsFailure(error));
  }
}

function* searchViewAsVendorsHandler(action) {
  try {
    const data = yield call([VendorServices, VendorServices.getVendors], action.payload);
    yield put(searchViewAsVendorsSuccess(data));
  } catch (error) {
    console.error('error', error);
    yield put(searchViewAsVendorsFailure(error));
  }
}

function* fetchVendorConfig(action) {
  const vendorId = action.payload;
  try {
    const response = yield call([ConfigServices, ConfigServices.getConfiguration], vendorId, "VendorConfiguration");
    yield put(getVendorConfigSuccess(response));
  } catch (error) {
    console.error('Error fetching vendor FTP config:', error);
    yield put(getVendorConfigFailure(error));
    toast.error('Failed to fetch vendor FTP configuration', { theme: 'colored' });
  }
}

function* updateVendorConfigHandler(action) {
  const { vendorId, data, cb } = action.payload;
  try {
    const schemaVersion = yield select(selectVendorSchemaVersion);

    const response = yield call([ConfigServices, ConfigServices.updateConfiguration], {
      ownerId: vendorId,
      schemaName: "VendorConfiguration",
      schemaVersion,
      data,
    });
    yield put(updateVendorConfigSuccess(response));
    toast.success('Vendor FTP configuration updated successfully', { theme: 'colored' });
    if (cb) cb();
  } catch (error) {
    console.error('Error updating vendor FTP config:', error);
    yield put(updateVendorConfigFailure(error));
    toast.error('Failed to update vendor FTP configuration', { theme: 'colored' });
  }
}

function* createVendorConfigHandler(action) {
  const { vendorId, data, cb } = action.payload;
  const schemaVersion = yield select(selectVendorSchemaVersion);

  try {
    const response = yield call([ConfigServices, ConfigServices.createConfiguration], {
      ownerId: vendorId,
      schemaName: "VendorConfiguration",
      schemaVersion,
      data,
    });
    yield put(createVendorConfigSuccess(response));
    toast.success('Vendor FTP configuration created successfully', { theme: 'colored' });
    if (cb) cb();
  } catch (error) {
    console.error('Error creating vendor FTP config:', error);
    yield put(createVendorConfigFailure(error));
    toast.error('Failed to create vendor FTP configuration', { theme: 'colored' });
  }
}

function* watchData() {
  yield takeEvery(getAllVendors().type, fetchAllVendors);
  yield takeEvery(getVendors().type, fetchVendors);
  yield takeEvery(getVendorsBulk().type, fetchVendorsBulk);
  yield takeEvery(getVendorDetails.toString(), fetchVendorDetails);
  yield takeEvery(getProductsByVendor.toString(), fetchProductsByVendor);
  yield takeEvery(getAllProductsByVendor.toString(), fetchAllProductsByVendor);
  yield takeEvery(createVendor.toString(), doCreateVendor);
  yield takeEvery(updateVendor.toString(), doUpdateVendor);
  yield takeEvery(getFacilityDetails.toString(), fetchFacilityDetails);
  yield takeEvery(addProductsToVendor.toString(), doAddProductsToVendor);
  yield takeEvery(updateVendorProducts.toString(), doUpdateVendorProducts);
  yield takeEvery(updateFacilityProduct.toString(), doUpdateFacilityProduct);
  yield takeEvery(addFacility.toString(), doAddFacility);
  yield takeEvery(updateFacility.toString(), doUpdateFacility);
  yield takeEvery(getAuditLogs.toString(), fetchAuditLogs);
  yield takeEvery(getVendorAuditInfo.toString(), fetchVendorAuditInfo);
  yield takeEvery(getFacilityAuditInfo.toString(), fetchFacilityAuditInfo);
  yield takeEvery(getVendorProductAuditInfo.toString(), fetchVendorProductAuditInfo);
  yield takeEvery(getAllValueAddedServices.toString(), fetchAllValueAddedServices);
  yield takeLatest(bulkGetFacilities.toString(), bulkGetFacilitiesHandler);
  yield takeLatest(searchVendors.toString(), searchVendorsHandler);
  yield takeLatest(searchViewAsVendors.toString(), searchViewAsVendorsHandler);
  yield takeEvery(getVendorConfig.toString(), fetchVendorConfig);
  yield takeLatest(updateVendorConfig.toString(), updateVendorConfigHandler);
  yield takeLatest(createVendorConfig.toString(), createVendorConfigHandler);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}