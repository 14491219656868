import { put, takeEvery, call, all } from 'redux-saga/effects';
import {
  getImageInfo,
  getImageInfoSuccess,
  getImageInfoFailure,
} from "app/store/actions/files";
import FilesServices from 'app/services/filesServices';

function* fetchImageInfo(action) {
  const { images, cb, cbError } = action.payload;

  try {
    const imageInfoList = yield all(images.map(function*(image) {
      // if the image does not have an ourFileId, return the image object as is
      if (!image.ourFileId) return image;

      const { imageInfo } = yield call(FilesServices.getImageInfo, image.ourFileId);
      return { ...image, imageInfo };
    }));

    yield put(getImageInfoSuccess(imageInfoList));

    if (cb) cb(imageInfoList);
  } catch (error) {
    yield put(getImageInfoFailure(error));
    if (cbError) cbError(error);
  }
}

function* uploadFileToBlobStorage(action) {
  const { file } = action.payload;
  try {
    const data = {
      "fileCategory": "plugin-logos",
      "public": false,
      "uploadLater": true,
    }
    // first make an api call to get the signed blob url
    const { fileInfo: { url: signedUrl } } = yield call(FilesServices.createFile, data);

    yield call(FilesServices.updateToBlobStorage, signedUrl, file);
  } catch (error) {
    console.error('Image upload error:', error);
    return {
      success: false,
      error: error.message
    };
  }
}

function* watchData() {
  yield takeEvery(getImageInfo.toString(), fetchImageInfo);
  yield takeEvery(uploadFileToBlobStorage.toString(), uploadFileToBlobStorage);
}

export default function* rootSaga() {
  yield all([
    watchData(),
  ]);
}
