import axios from "axios";
import Config from "../config";
import SetupInterceptors from './axiosInterceptors';

class FilesServices {
    constructor() {
        this.api = axios.create({
            baseURL: Config.FilesMS.Url
        });
        SetupInterceptors(this.api);
    }

    getFile = async (fileId) => {
        try {
            const response = await this.api.get(`/v1/files/${fileId}`);
            return response.data;
        } catch (error) {
            throw new Error('Error fetching file data from API');
        }
    }

    getImageInfo = async (fileId) => {
        try {
            const response = await this.api.get(`/v1/files/${fileId}/imageInfo`);
            return response.data;
        } catch (error) {
            throw new Error('Error fetching image info from API');
        }
    }

    createFile = async (data) => {
        try {
            const response = await this.api.post('/v1/files', data);
            return response.data;
        } catch (error) {
            console.log("error", error);
            throw new Error('Error creating file from API');
        }
    }

    updateToBlobStorage = async (signedUrl, uploadedFile) => {
        try {
            let fileData = uploadedFile.preview;
            if (typeof fileData === 'string' && fileData.startsWith('data:')) {
                // remove the data URI prefix
                const base64Data = fileData.split(',')[1];
                // convert base64 to blob
                const byteCharacters = atob(base64Data);
                const byteArrays = [];
                
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteArrays.push(byteCharacters.charCodeAt(i));
                }
                
                const byteArray = new Uint8Array(byteArrays);
                fileData = new Blob([byteArray], { type: uploadedFile.file.type });
            }
    
            const response = await fetch(signedUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': uploadedFile.file.type,
                    'x-ms-blob-type': 'BlockBlob'
                },
                body: fileData
            });
    
            if (!response.ok) {
                throw new Error(`Upload failed: ${response.statusText}`);
            }
    
            return {
                success: true,
                url: signedUrl.split('?')[0]
            };
        } catch (error) {
            throw new Error('Error updating file to blob storage from API');
        }
    }
}

export default new FilesServices();