import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getClient, createClient, generateSecret, deleteClient, toggleGenerateSecret, toggleDeleteClient, cancel, reset } from "app/store/actions/client";
import { clientDataSelector, clientLoadingSelector, clientErrorSelector, confirmGenerateSecretSelector,  confirmDeleteClientSelector } from "app/store/selectors/client";
import { LoadingAnimation, Card, Button, DataPoint, Modal, MessageBar } from 'app/components';
import PropTypes from "prop-types";
import "./index.scss";

const ClientManagement = ({ entityType, entityId }) => {
  const dispatch = useDispatch();
  const clientData = useSelector(clientDataSelector);
  const loading = useSelector(clientLoadingSelector);
  const error = useSelector(clientErrorSelector);
  const confirmGenerateSecret = useSelector(confirmGenerateSecretSelector);
  const confirmDeleteClient = useSelector(confirmDeleteClientSelector);
  const noClient = !loading && !!clientData && !clientData.id;
  const hasClient = !!clientData && !!clientData.id;
  
  useEffect(() => {
    dispatch(reset());
    dispatch(getClient({  entityType, entityId }));
  }, [dispatch, entityType, entityId]);

  return (
    <div className="client-manager-card-data">
      {loading && <LoadingAnimation />}
      {error && (
        <div className="loading">
          <MessageBar color="yellow">An error occurred while fetching data</MessageBar>
        </div>
      )}
      <Card className="client-manager-card">
        <Card.Header>
          API Authentication
          {noClient && (
            <Button
              variant="primary"
              size="small"
              label="Create Client"
              onClick={() => dispatch(createClient({ role : entityType, id : entityId }))}
            />
          )}
          {hasClient && (
            <div className="action-buttons">
              <Button
                variant="secondary"
                size="small"
                label="Regenerate secret"
                disabled={loading}
                onClick={() => dispatch(toggleGenerateSecret())}
              />
              <Button
                variant="primary"
                size="small"
                label="Delete Client"
                disabled={loading}
                onClick={() => dispatch(toggleDeleteClient())}
              />
            </div>
          )}
        </Card.Header>
        <Card.Body>
          <div className="client-information">
            <div>These keys will allow you to authenticate API requests</div>
            {hasClient && (
              <div className="client-details">
                  <DataPoint title="Client Id" data={clientData.clientId} />
                  <DataPoint title="Secret" data={clientData.secret || 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'} />
                  {clientData.secret && (
                    <MessageBar color="blue">Important: This secret key will only be displayed once. Please ensure you save it securely, as you won&apos;t be able to view it again after leaving this page.</MessageBar>
                  )}
              </div>
            )}
          </div>
        </Card.Body>
      </Card>
      {confirmGenerateSecret && (
        <Modal
          title="Regenerate the secret"
          primaryButtonLabel="Regenerate"
          primaryButtonVariant="primary"
          primaryButtonDestructive
          primaryButtonOnClick={() => dispatch(generateSecret(clientData.id))}
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={() => dispatch(cancel())}
          onClose={() => dispatch(cancel())}
        >
          <div>The previous secret will be revoked.</div>
          <div>This action cannot be undone.</div>
        </Modal>
      )}
      {confirmDeleteClient && (
        <Modal
          title="Delete Client"
          primaryButtonLabel="Delete"
          primaryButtonVariant="primary"
          primaryButtonDestructive
          primaryButtonOnClick={() => dispatch(deleteClient(clientData.id))}
          secondaryButtonLabel="Cancel"
          secondaryButtonOnClick={() => dispatch(cancel())}
          onClose={() => dispatch(cancel())}
        >
          <div>The Client and secret will be revoked.</div>
          <div>This action cannot be undone.</div>
        </Modal>
      )}
    </div>
  );
};

ClientManagement.propTypes = {
  entityId: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
};

export default ClientManagement;
