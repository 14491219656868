import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class VendorServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.VendorMS.Url
    });

    SetupInterceptors(this.api);
  }

  createVendor = async (data) => {
    try {
      const response = await this.api.post(`/v1/vendors`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error creating vendor');
    }
  };

  getVendors = async (data) => {
    const {
      searchString,
      currentPage,
      pageSize,
      sortBy,
      sortDir,
      disableXAccessHeader = false
    } = data;

    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('searchAll', searchString);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');

    try {
      let url = `/v1/vendors?${queryString}`;
      if (disableXAccessHeader) {
        url += "&disableXAccessHeader";
      }

      const response = await this.api.get(url);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getAllVendors = async (params = {}) => {
    const query = { ...params };
    query.currentPage = query.currentPage || 1;
    query.pageSize = query.pageSize || 250;

    let allVendors = [];
    let total = 0;

    do {
      const response = await this.getVendors(query);
      const { total: totalFromApi, vendors } = response;

      if (!total) {
        total = totalFromApi;
      }
      allVendors.push(...vendors);

      query.currentPage += 1;

    } while (allVendors.length < total);

    return {
      total,
      vendors: allVendors
    };
  };

  getVendorsBulk = async (data) => {
    const queryString = data.map(id => `bulk=${id}`).join('&');

    try {
      const response = await this.api.get(`/v1/vendors/bulk?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  };

  getVendorDetails = async (vendorId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching vendor details');
    }
  };

  updateVendor = async (vendorId, data) => {
    try {
      const response = await this.api.patch(`/v1/vendors/${vendorId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating vendor details');
    }
  };

  deleteVendor = async (vendorId) => {
    try {
      const response = await this.api.delete(`/v1/vendors/${vendorId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error deleting vendor');
    }
  };

  getProductsByVendor = async (vendorId, facilityId, searchString, currentPage, pageSize, sortBy, sortDir, showBy, vendorIds) => {
    let queryString = "";

    // helper function to append parameter if it's not null
    const addParam = (key, value) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `&${key}=${encodeURIComponent(value)}`;
      }
    };

    // append parameters dynamically if they are not null
    addParam('vendorId', vendorId);
    addParam('facilityId', facilityId);
    addParam('searchAll', searchString);
    addParam('page', currentPage);
    addParam('pageSize', pageSize);
    addParam('sortBy', sortBy);
    addParam('sortAsc', sortDir === 'asc');
    addParam('showBy', showBy);
    vendorIds?.map(vi => addParam("vendorIds", vi));

    try {
      const response = await this.api.get(`/v1/vendorProducts?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching vendor details');
    }
  };

  addProductsToVendor = async (data) => {
    const response = await this.api.post('/v1/vendorProducts/bulk', data);
    return response.data;
  };

  updateVendorProducts = async (data) => {
    const response = await this.api.put('/v1/vendorProducts/bulk', data);
    return response.data;
  }

  updateFacilityProduct = async (data, productId, facilityId) => {
    const response = await this.api.put(`/vendorProducts/${productId}/facilities/${facilityId}`, data);
    return response.data;
  }

  addFacility = async (vendorId, data) => {
    try {
      const response = await this.api.post(`/v1/vendors/${vendorId}/facilities`, data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  };

  updateFacility = async (vendorId, facilityId, data) => {
    try {
      const response = await this.api.put(`/v1/vendors/${vendorId}/facilities/${facilityId}`, data);
      return response.data;
    } catch (error) {
      throw new Error('Error updating facility details');
    }
  };

  getFacilityDetails = async (vendorId, facilityId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}/facilities/${facilityId}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching facility details');
    }
  };

  getAuditLogs = async (vendorId) => {
    try {
      const response = await this.api.get(`/v1/vendors/${vendorId}/auditLogs`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching audit logs');
    }
  }

  getAuditInfo = async (auditUrl) => {
    try {
      auditUrl = auditUrl.replace(/.*vendors/, 'v1/vendors');
      auditUrl = auditUrl.replace(/.*vendorProducts/, 'v1/vendorProducts');
      auditUrl = auditUrl.replace('?', '/auditLogs?');
      auditUrl = auditUrl.replace('DIFF', 'FULL');

      const response = await this.api.get(auditUrl);
      return response.data;
    }
    catch (error) {
      throw new Error('Error fetching audit info');
    }
  }

  getAllValueAddedServices = async () => {
    try {
      const response = await this.api.get(`/v1/vas`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching value added services');
    }
  };

  bulkGetFacilities = async (facilityIds) => {
    const queryString = facilityIds.map(id => `bulk=${id}`).join('&');

    try {
      const response = await this.api.get(`/v1/facilities/bulk?${queryString}`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching data from API');
    }
  }
}

export default new VendorServices();