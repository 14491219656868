import axios from 'axios';
import Config from '../config';
import SetupInterceptors from './axiosInterceptors';

class InsightServices {
  constructor() {
    this.api = axios.create({
      baseURL: Config.InsightMS.Url
    });

    SetupInterceptors(this.api);
  }

  getAllQueries = async () => {
    try {
      const response = await this.api.get(`/v1/query`);
      return response.data;
    } catch (error) {
      throw new Error('Error fetching queries from API');
    }
  };

  runQueryById = async (queryId, runAs, dateRange, merchantIds, vendorIds) => {
    try {
      let queryString = "";

      const addParam = (key, value) => {
        if (value !== null && value !== undefined && value !== "") {
          queryString += `&${key}=${encodeURIComponent(value)}`;
        }
      };

      if (dateRange) {
        const startDate = new Date();
        startDate.setDate(startDate.getDate() - dateRange);
        addParam('startDate', startDate.toISOString()?.split('T')[0]);
      }

      addParam('runAs', runAs);

      if (merchantIds) {
        merchantIds.forEach(m => {
          addParam('merchantIds', m);
        });
      }

      if (vendorIds) {
        vendorIds.forEach(v => {
          addParam('vendorIds', v);
        });
      }

      // runAs not needed for now...
      const response = await this.api.get(`/v1/query/${queryId}/run?${queryString}`);
      return response.data;
    } catch (error) {
      console.log('Error running query', error);
      throw new Error('Error running query');
    }
  };
}

export default new InsightServices();