import axios from 'axios';
import Config from '../config';
import { store, persistor } from 'app/store/store';
import { updateTokens } from 'app/store/actions/user';

let isRefreshing = false;
let refreshTokenPromise = null;

const SetupInterceptors = (api) => {
  api.interceptors.request.use(
    async (config) => {
      const state = store.getState();
      const viewAsUser = state.user?.viewAs;
      const { bearerToken, expiresAt } = state.user?.auth || {};

      const currentTime = Date.now();

      if (expiresAt && currentTime > expiresAt) {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshTokenPromise = refreshToken()
            .then((newToken) => {
              isRefreshing = false;
              refreshTokenPromise = null;
              return newToken;
            })
            .catch(async (error) => {
              isRefreshing = false;
              refreshTokenPromise = null;

              // Clear Redux store and local storage
              persistor.pause();
              await persistor.flush().then(async () => {
                persistor.purge();
                localStorage.clear();
                sessionStorage.clear();
                window.location.reload();
              }).catch(() => {
                window.location.reload();
              });

              throw error;
            });
        }
        try {
          const newToken = await refreshTokenPromise;
          config.headers.Authorization = `Bearer ${newToken}`;
        } catch (error) {
          return Promise.reject(error);
        }
      } else if (bearerToken) {
        config.headers.Authorization = `Bearer ${bearerToken}`;
      }
      
      // in some cases we have to disable the X-Access-IDs header, specifically
      // when we made api calls from ViewAs component...
      const shouldDisableXAccessHeader = config.url.includes('disableXAccessHeader');

      if (viewAsUser == null || viewAsUser == undefined || shouldDisableXAccessHeader) {
        config.headers = {
          ...config.headers,
          'Content-Type': 'application/json'
        };
      } else {
        config.headers = {
          ...config.headers,
          'Content-Type': 'application/json',
          "X-Access-IDs": `${viewAsUser?.userTypePrefix}${viewAsUser?.id}`
        };
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const isLoginRequest = originalRequest.url.includes('/login');

      if (error.response.status === 401 && !originalRequest._retry && !isLoginRequest) {
        originalRequest._retry = true;
        try {
          return await axiosRetry(originalRequest);
        } catch (refreshError) {
          // getting to this point means our bearer token AND the refresh token have expired.  The only thing we can do
          // is log them out and force them to re-login.  Before we can do that, we need to completely clear the redux store
          // so when the page is reloaded our redux-persist library doesn't refresh it with the old data.

          // pause persisting to avoid saving any new state changes
          persistor.pause();
          persistor.flush().then(() => {
            persistor.purge();
            window.location.reload();
          });

          // delay the throw using setTimeout.  The page reload is not immediate.  We don't want to throw the error back to the
          // calling function as it will then display an error message on the screen.  Instead, let's just force the code to
          // sit idle while we wait for the page to reload (which restarts the application fresh)
          return new Promise((_, reject) => {
            setTimeout(() => {
              reject(refreshError);
            }, 5000); // some arbitrary time that is larger than the time it takes for the page to reload
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

const axiosRetry = async (originalRequest) => {
  const refreshedToken = await refreshToken();
  if (refreshedToken) {
    originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;
    return axios(originalRequest);
  } else {
    // refresh token is expired
    throw new Error();
  }
};

export const refreshToken = async () => {
  try {
    const state = store.getState();
    const refreshToken = state.user?.auth?.refreshToken;

    const data = JSON.stringify({ refreshToken });

    const response = await axios.post(`${Config.UserMS.Url}/v1/refresh`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.data?.isError) {
      const { accessToken, refreshToken, expiresIn } = response.data;
      store.dispatch(updateTokens({ accessToken, refreshToken, expiresIn }));
      return accessToken;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Error fetching data from API');
  }
};

export default SetupInterceptors;
