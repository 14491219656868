import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { reset } from 'app/store/actions/notification';
import { Card, Button, TabbedMenu } from 'app/components';
import { isMerchant } from 'app/permissions';
import PublicIntegrations from './PublicIntegrations';
import MyIntegrations from './MyIntegrations';
import './index.scss';

const IntegrationsView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMerchantUser = isMerchant();

  const tabs = [
    {
      path: '/admin/plugins',
      label: 'Plugins',
      content: PublicIntegrations,
    },
    ...(isMerchantUser ? [{
      path: '/admin/plugins/my-plugins',
      label: 'My Plugins',
      content: MyIntegrations,
    }] : []),
  ];

  return (
    <div className="integrations-view">
      <Card className="integrations-header">
        Plugins
        <Button
          variant="primary"
          size="medium"
          label={"Manage Plugins"}
          onClick={() => {
            dispatch(reset());
            navigate(`/admin/plugins/manage-plugins`);
          }}
        />
      </Card>
      <TabbedMenu tabs={tabs} />
    </div>
  )
}

export default IntegrationsView;