import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMenu, Button, IconData } from 'app/components';
import { disconnectIntegration } from 'app/store/actions/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import { ReactComponent as IndustryWindows } from 'app/assets/images/icons/industry-windows.svg';
import { ReactComponent as CodeBranch } from 'app/assets/images/icons/code-branch.svg';
import { ReactComponent as Logo } from "app/assets/images/logos/main-logo-dark.svg";
import { PlugFill } from 'react-bootstrap-icons';
import './index.scss';

const PluginCard = ({
  plugin,
  merchantView = false,
  isMerchantUser = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentUser = useSelector(currentUserSelector);

  const integrationItemOptions = () => [
    {
      value: 'Edit Plugin',
      label: 'Edit Plugin',
      onClick: () => {
        navigate(`/admin/plugins/edit-variables/${plugin.merchantPluginId}`);
      },
    },
    {
      value: 'Disconnect',
      label: 'Disconnect',
      destructive: true,
      onClick: () => {
        dispatch(
          disconnectIntegration({
            merchantId: currentUser.attributes?.entityId?.[0],
            merchantPluginId: plugin.merchantPluginId,
          })
        );
      },
    },
  ];

  return (
    <div className="plugin-card">
      <div className="plugin-logo">
        {plugin.logoUrl ? <img src={plugin.logoUrl} alt={plugin.vendorName} /> : <Logo />}
      </div>
      <div className="header-name">{plugin.name || plugin.pluginName}</div>
      <div className="plugin-card-content">
        {(plugin.currentPluginVersionName || plugin.latestPublishedVersionName) && (
          <IconData label={plugin.currentPluginVersionName || plugin.latestPublishedVersionName} icon={<CodeBranch />} />
        )}
        {plugin.vendorName && (
          <IconData label={plugin.vendorName} icon={<IndustryWindows />} enableClipboardCopy={false} />
        )}
      </div>
      <div className="plugin-description">{plugin.pluginDescription}</div>
      <div className="plugin-actions">
        {!merchantView && isMerchantUser && (
          <Button
            className="connect-button"
            label={plugin.alreadyConnected ? 'View' : 'Connect'}
            size="small"
            imageLeft={<PlugFill />}
            imageLeftOffset={-2}
            onClick={() => {
              if (plugin.alreadyConnected) {
                navigate(`/admin/plugins/my-plugins`);
              } else {
                navigate(`/admin/plugins/connect-variables/${plugin.id}/${plugin.latestPublishedVersionId}`);
              }
            }}
          />
        )}
        {merchantView && (
          <ButtonMenu
            label="Actions"
            options={integrationItemOptions()}
          />
        )}
      </div>
    </div>
  );
};

PluginCard.propTypes = {
  plugin: PropTypes.shape({
    vendorName: PropTypes.string,
    latestPublishedVersionName: PropTypes.string,
    merchantPluginId: PropTypes.string,
    alreadyConnected: PropTypes.bool,
  }).isRequired,
  merchantView: PropTypes.bool,
  isMerchantUser: PropTypes.bool,
};

export default PluginCard;
