import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Pagination, LoadingAnimation, MessageBar } from 'app/components';
import { merchantIntegrationsDataSelector, merchantIntegrationsLoadingSelector, merchantIntegrationsErrorsSelector } from 'app/store/selectors/integration';
import { currentUserSelector } from 'app/store/selectors/user';
import { getMerchantIntegrations, reset } from 'app/store/actions/integration';
import PluginCard from '../PluginCard';
import './index.scss';

const MyIntegrations = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const [hasReset, setHasReset] = useState(false);

  const currentUser = useSelector(currentUserSelector);
  const integrationsData = useSelector(merchantIntegrationsDataSelector);
  const loading = useSelector(merchantIntegrationsLoadingSelector);
  const errorsFound = useSelector(merchantIntegrationsErrorsSelector);

  useEffect(() => {
    dispatch(reset());
    setHasReset(true);
  }, [dispatch]);

  useEffect(() => {
    if (hasReset) {
      dispatch(getMerchantIntegrations({
        merchantId: currentUser.attributes?.entityId?.[0],
        currentPage,
        pageSize,
        isPublished: true,
        isMerchant: true
      }));
    }
  }, [dispatch, currentPage, pageSize, currentUser.attributes?.entityId, hasReset]);

  if (!hasReset) {
    return null;
  }

  return (
    <div className="my-integrations">
      {loading && <LoadingAnimation />}
      <Card className={`my-integrations-card ${errorsFound ? 'errors-found' : ''}`}>
        <div className="my-integrations-header">
          Plugins
        </div>
        {!errorsFound && (
          <>
            <div className="my-integrations-content">
              {(!integrationsData || integrationsData?.plugins?.length === 0) && !loading ? (
                <div className="no-integrations-found">
                  No Plugins Found
                </div>
              ) : (
                <div className="content-list">
                  {integrationsData?.plugins?.map((plugin) => (
                    <PluginCard key={plugin.pluginId} plugin={plugin} merchantView={true} isMerchantUser={true} />
                  ))}
                </div>
              )}
            </div>
            <Pagination totalItems={integrationsData?.plugins?.length || 0} />
          </>
        )}
        {errorsFound && !loading && (
          <MessageBar color="yellow">
            An error occurred while loading plugin data
          </MessageBar>
        )}
      </Card>
    </div>
  )
}

export default MyIntegrations;
