import {
  getVendors,
  getVendorsSuccess,
  getVendorsFailure,
  getAllVendors,
  getAllVendorsSuccess,
  getAllVendorsFailure,
  getVendorsBulk,
  getVendorsBulkSuccess,
  getVendorsBulkFailure,
  getVendorDetails,
  getVendorDetailsSuccess,
  getVendorDetailsFailure,
  createVendor,
  createVendorSuccess,
  createVendorFailure,
  updateVendor,
  updateVendorSuccess,
  updateVendorFailure,
  getFacilityDetails,
  getFacilityDetailsSuccess,
  getFacilityDetailsFailure,
  getProductsByVendor,
  getProductsByVendorSuccess,
  getProductsByVendorFailure,
  getAllProductsByVendor,
  getAllProductsByVendorSuccess,
  getAllProductsByVendorFailure,
  addProductsToVendor,
  addProductsToVendorSuccess,
  addProductsToVendorFailure,
  updateVendorProducts,
  updateVendorProductsSuccess,
  updateVendorProductsFailure,
  updateFacilityProduct,
  updateFacilityProductSuccess,
  updateFacilityProductFailure,
  addFacility,
  addFacilitySuccess,
  addFacilityFailure,
  updateFacility,
  updateFacilitySuccess,
  updateFacilityFailure,
  getAuditLogs,
  getAuditLogsSuccess,
  getAuditLogsFailure,
  getAllValueAddedServices,
  getAllValueAddedServicesSuccess,
  getAllValueAddedServicesFailure,
  clearErrors,
  reset,
  setVendorSearchString,
  bulkGetFacilities,
  bulkGetFacilitiesSuccess,
  bulkGetFacilitiesFailure,
  searchVendors,
  searchVendorsSuccess,
  searchVendorsFailure,
  searchViewAsVendors,
  searchViewAsVendorsSuccess,
  searchViewAsVendorsFailure,
  getVendorConfig,
  getVendorConfigSuccess,
  getVendorConfigFailure,
  updateVendorConfig,
  updateVendorConfigSuccess,
  updateVendorConfigFailure,
  createVendorConfig,
  createVendorConfigSuccess,
  createVendorConfigFailure,
} from "app/store/actions/vendor"
import { handleActions } from 'redux-actions';

const initialState = {
  vendors: {
    loading: false,
    data: null,
    error: null,
  },
  vendor: {
    loading: false,
    data: null,
    error: null,
  },
  vendorsBulk: {
    loading: false,
    data: null,
    error: null,
  },
  facility: {
    loading: false,
    data: null,
    error: null,
  },
  products: {
    loading: false,
    data: null,
    error: null,
  },
  allProducts: {
    loading: false,
    data: null,
    error: null,
  },
  auditLogs: {
    loading: false,
    data: null,
    error: null,
  },
  addUpdateProducts: {
    loading: false,
    error: null,
  },
  vas: {
    loading: false,
    data: null,
    error: null,
  },
  bulkFacilities: {
    loading: false,
    data: null,
    error: null
  },
  searchString: '',
  searchVendors: {
    loading: false,
    data: null,
    error: null
  },
  searchViewAsVendors: {
    loading: false,
    data: null,
    error: null
  },
  vendorConfig: {
    data: {
      FTPInventorySettings: {
        FtpEnabled: false,
        FtpHost: '',
        FtpPort: 22,
        FtpProtocol: 'SFTP',
        FtpUsername: '',
        FtpPassword: '',
        FtpInventoryFileDirectory: '/inventory',
        FtpInventoryFileName: 'inventory_{{YYYY}}_{{MM}}_{{DD}}_{{HH}}{{*}}.csv',
        FtpInventoryControlFileName: 'control_{{YYYY}}_{{MM}}_{{DD}}_{{HH}}{{*}}.csv',
      }

    },
    loading: false,
    error: null,
  }
}

const vendorReducer = handleActions(
  {
    [getVendors]: (state) => ({
      ...state,
      vendors: {
        ...state.vendors,
        loading: true,
        error: null,
      }
    }),

    [getVendorsSuccess]: (state, data) => ({
      ...state,
      vendors: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getVendorsFailure]: (state, data) => ({
      ...state,
      vendors: {
        ...state.vendors,
        loading: false,
        error: data.payload,
      }
    }),
    [getAllVendors]: (state) => ({
      ...state,
      vendors: {
        ...state.vendors,
        loading: true,
        error: null,
      }
    }),

    [getAllVendorsSuccess]: (state, data) => ({
      ...state,
      vendors: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getAllVendorsFailure]: (state, data) => ({
      ...state,
      vendors: {
        ...state.vendors,
        loading: false,
        error: data.payload,
      }
    }),
    [getVendorsBulk]: (state) => ({
      ...state,
      vendorsBulk: {
        ...state.vendorsBulk,
        loading: true,
        error: null,
      }
    }),

    [getVendorsBulkSuccess]: (state, data) => ({
      ...state,
      vendorsBulk: {
        loading: false,
        data: data.payload,
        error: null,
      }
    }),

    [getVendorsBulkFailure]: (state, data) => ({
      ...state,
      vendorsBulk: {
        loading: false,
        data: null,
        error: data.payload,
      }
    }),

    [getVendorDetails]: (state) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [getVendorDetailsSuccess]: (state, data) => ({
      ...state,
      vendor: {
        loading: false,
        data: data.payload.vendor,
        error: null,
      },
    }),

    [getVendorDetailsFailure]: (state, data) => ({
      ...state,
      vendor: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [createVendor]: (state) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [createVendorSuccess]: (state) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: null,
      },
    }),

    [createVendorFailure]: (state, data) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: data.payload,
      },
    }),

    [updateVendor]: (state) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: true,
        error: null,
      },
    }),

    [updateVendorSuccess]: (state, data) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        data: {
          ...state.vendor.data,
          ...data.payload.data,
        },
        error: null,
      },
    }),

    [updateVendorFailure]: (state, data) => ({
      ...state,
      vendor: {
        ...state.vendor,
        loading: false,
        error: data.payload,
      },
    }),

    [getFacilityDetails]: (state) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [getFacilityDetailsSuccess]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: data.payload.facility,
        error: null,
      },
    }),

    [getFacilityDetailsFailure]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [getProductsByVendor]: (state) => ({
      ...state,
      products: {
        ...state.products,
        loading: true,
        error: null,
      },
    }),

    [getProductsByVendorSuccess]: (state, data) => ({
      ...state,
      products: {
        loading: false,
        data: data.payload?.data,
        error: null,
      },
    }),

    [getProductsByVendorFailure]: (state, data) => ({
      ...state,
      products: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [getAllProductsByVendor]: (state) => ({
      ...state,
      allProducts: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAllProductsByVendorSuccess]: (state, data) => ({
      ...state,
      allProducts: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getAllProductsByVendorFailure]: (state, data) => ({
      ...state,
      allProducts: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [addProductsToVendor]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: true,
        error: null,
      },
    }),

    [addProductsToVendorSuccess]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: null,
      },
    }),

    [addProductsToVendorFailure]: (state, data) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: data.payload,
      },
    }),

    [updateVendorProducts]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: true,
        error: null,
      },
    }),

    [updateVendorProductsSuccess]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: null,
      },
    }),

    [updateVendorProductsFailure]: (state, data) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: data.payload,
      },
    }),

    [updateFacilityProduct]: (state) => ({
      ...state,
      addUpdateProducts: {
        loading: true,
        error: null,
      },
    }),

    [updateFacilityProductSuccess]: (state, data) => {
      // locate and update the product
      const products = { ...state.products };
      const productId = data.payload.resp.data.id;
      const productIndex = products.data.vendorProducts.findIndex(product => product.id === productId);
      products.data.vendorProducts[productIndex] = data.payload.resp.data;

      return ({
        ...state,
        products,
        addUpdateProducts: {
          loading: false,
          error: null,
        },
      })
    },

    [updateFacilityProductFailure]: (state, data) => ({
      ...state,
      addUpdateProducts: {
        loading: false,
        error: data.payload,
      },
    }),

    [addFacility]: (state) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [addFacilitySuccess]: (state, data) => ({
      ...state,
      facility: {
        loading: false,
        data: data.payload.data,
        error: null,
      },
    }),

    [addFacilityFailure]: (state, data) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: false,
        error: data.payload,
      },
    }),

    [updateFacility]: (state) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: true,
        error: null,
      },
    }),

    [updateFacilitySuccess]: (state, data) => {
      const { resp } = data.payload;

      return ({
        ...state,
        facility: {
          loading: false,
          data: resp.facility,
          error: null,
        },
      })
    },

    [updateFacilityFailure]: (state, data) => ({
      ...state,
      facility: {
        ...state.facility,
        loading: false,
        error: data.payload,
      },
    }),

    [getAuditLogs]: (state) => ({
      ...state,
      auditLogs: {
        ...state.auditLogs,
        loading: true,
        error: null,
      },
    }),

    [getAuditLogsSuccess]: (state, data) => ({
      ...state,
      auditLogs: {
        loading: false,
        data: data.payload,
        error: null,
      },
    }),

    [getAuditLogsFailure]: (state, data) => ({
      ...state,
      auditLogs: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [getAllValueAddedServices]: (state) => ({
      ...state,
      vas: {
        ...state.vas,
        loading: true,
        error: null,
      },
    }),

    [getAllValueAddedServicesSuccess]: (state, data) => ({
      ...state,
      vas: {
        loading: false,
        data: data.payload.vas,
        error: null,
      },
    }),

    [getAllValueAddedServicesFailure]: (state, data) => ({
      ...state,
      vas: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [setVendorSearchString]: (state, data) => ({
      ...state,
      searchString: data.payload,
    }),

    [bulkGetFacilities]: (state) => ({
      ...state,
      bulkFacilities: {
        loading: true,
        data: null,
        error: null
      },
    }),

    [bulkGetFacilitiesSuccess]: (state, data) => ({
      ...state,
      bulkFacilities: {
        loading: false,
        data: data.payload,
        error: null
      },
    }),

    [bulkGetFacilitiesFailure]: (state, data) => ({
      ...state,
      bulkFacilities: {
        loading: false,
        data: null,
        error: data.payload
      },
    }),

    [searchVendors]: (state) => ({
      ...state,
      searchVendors: {
        loading: true,
        data: null,
        error: null
      },
    }),

    [searchVendorsSuccess]: (state, data) => ({
      ...state,
      searchVendors: {
        loading: false,
        data: data.payload,
        error: null
      },
    }),

    [searchVendorsFailure]: (state, data) => ({
      ...state,
      searchVendors: {
        loading: false,
        data: null,
        error: data.payload
      },
    }),

    [searchViewAsVendors]: (state) => ({
      ...state,
      searchViewAsVendors: {
        loading: true,
        data: null,
        error: null
      },
    }),

    [searchViewAsVendorsSuccess]: (state, data) => ({
      ...state,
      searchViewAsVendors: {
        loading: false,
        data: data.payload,
        error: null
      },
    }),

    [searchViewAsVendorsFailure]: (state, data) => ({
      ...state,
      searchViewAsVendors: {
        loading: false,
        data: null,
        error: data.payload
      },
    }),

    [getVendorConfig]: (state) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: true, error: null },
    }),
    [getVendorConfigSuccess]: (state, data) => ({
      ...state,
      vendorConfig: { data: data.payload, loading: false, error: null },
    }),
    [getVendorConfigFailure]: (state, data) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: false, error: data },
    }),

    [updateVendorConfig]: (state) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: true, error: null },
    }),
    [updateVendorConfigSuccess]: (state, data) => ({
      ...state,
      vendorConfig: { data: data.payload, loading: false, error: null },
    }),
    [updateVendorConfigFailure]: (state, data) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: false, error: data.payload },
    }),

    [createVendorConfig]: (state) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: true, error: null },
    }),
    [createVendorConfigSuccess]: (state, data) => ({
      ...state,
      vendorConfig: { data: data.payload, loading: false, error: null },
    }),
    [createVendorConfigFailure]: (state, data) => ({
      ...state,
      vendorConfig: { ...state.vendorConfig, loading: false, error: data.payload },
    }),

    [clearErrors]: (state) => ({
      ...state,
      vendors: {
        ...state.vendors,
        error: null,
      },
      vendor: {
        ...state.vendor,
        error: null,
      },
      products: {
        ...state.products,
        error: null,
      },
      addUpdateProducts: {
        ...state.addUpdateProducts,
        error: null,
      },
      bulkFacilities: {
        ...state.bulkFacilities,
        error: null
      },
      searchVendors: {
        ...state.searchVendors,
        error: null
      },
      searchViewAsVendors: {
        ...state.searchViewAsVendors,
        error: null
      },
      vendorConfig: {
        ...state.vendorConfig,
        error: null,
      }
    }),

    [reset]: () => ({
      ...initialState
    })
  },
  initialState,
)

export default vendorReducer;