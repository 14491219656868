import React, { useState, useEffect } from 'react';
import { Card, Input, Button, MessageBar, ButtonIcon, LoadingAnimation } from 'app/components';
import { Upload, Trash3, InfoCircle } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import './index.scss';

const PluginMarketplaceSettings = ({
  values,
  errors,
  setFieldValue,
  submitCount,
}) => {
  const [file, setFile] = useState(null);
  const [dragging, setDragging] = useState(false);
  const [loading, setLoading] = useState(false);

  const processFile = (uploadedFile) => {
    if (!uploadedFile) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        setFieldValue('uploadedFile', {
          file: uploadedFile,
          preview: event.target.result,
          width: img.width,
          height: img.height,
        });
        setLoading(false);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(uploadedFile);
    setLoading(true);
  };

  const processImageUrl = (imageUrl) => {
    if (!imageUrl) return;

    setLoading(true);
    const img = new Image();
    img.onload = () => {
      setFieldValue('uploadedFile', {
        file: null,
        preview: imageUrl,
        width: img.width,
        height: img.height,
      });
      setLoading(false);
    };
    img.onerror = () => {
      setLoading(false);
      toast.error('Logo URL could not be loaded')
    };
    img.src = imageUrl;
  };

  useEffect(() => {
    if (values.imageUrl && !values.uploadedFile) {
      processImageUrl(values.imageUrl);
    }
  }, []);

  const handleFileChange = (e) => {
    processFile(e.target.files[0]);
  };

  const handleImageUrlChange = (e) => {
    setFieldValue('imageUrl', e.target.value);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const uploadedFile = e.dataTransfer.files[0];
    setFile(uploadedFile);
    processFile(uploadedFile);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setDragging(false);
  };

  const handleUploadAreaClick = () => {
    document.getElementById('file-upload').click();
  };

  const handleDescriptionChange = (e) => {
    setFieldValue('pluginDescription', e.target.value);
  };

  const handleRemoveFile = () => {
    setFieldValue('uploadedFile', null);
    setFieldValue('imageUrl', '');
    setLoading(false);
  };

  return (
    <Card className="plugin-marketplace-settings">
      <Card.Header>Plugin Marketplace Settings</Card.Header>
      <Card.Body>
        <div className="plugin-description-container">
          <Input
            label="Plugin Description"
            name="pluginDescription"
            placeholder="Add a Description for your Plugin"
            value={values.pluginDescription}
            onChange={handleDescriptionChange}
            maxLength={120}
            showErrorMessages={submitCount > 0 && errors.pluginDescription ? true : false}
            errorMessage={submitCount > 0 && errors.pluginDescription}
          />
          <div className="max-characters">Max Characters {values.pluginDescription.length}/120</div>
        </div>
        <div className="upload-container">
          <label className="upload-label">
            <div className="upload-label-text">
              <div>{file ? 'Uploaded Logo for Marketplace' : 'Upload Logo for Marketplace'}</div>
              {submitCount > 0 && errors.imageUrl ? (
                <div className="error-message">
                  {errors.imageUrl}
                </div>
              ) : submitCount > 0 && errors.uploadedFile ? (
                <div className="error-message">
                  {errors.uploadedFile}
                </div>
              ) : null}
            </div>
          </label>
          {loading ? (
            <div className="loading-container">
              <LoadingAnimation />
              <div>Loading image...</div>
            </div>
          ) : values.uploadedFile ? (
            <div className="uploaded-file-container">
              <img
                src={values.uploadedFile.preview}
                alt="Uploaded Logo"
                className="uploaded-logo"
              />
              <div className="file-details">
                <div className="file-name">{values.uploadedFile.file?.name || 'From URL'}</div>
                {errors.uploadedFile ? (
                  <MessageBar
                    color="red"
                    className="image-dimensions"
                  >
                    Image Size: {values.uploadedFile.width} x {values.uploadedFile.height}px.  Required size is 100x100px.
                  </MessageBar>
                ) : (
                  <MessageBar
                    color="blue"
                    icon={<InfoCircle />}
                    className="image-dimensions"
                  >
                    Image Size: {values.uploadedFile.width} x {values.uploadedFile.height}px
                  </MessageBar>
                )}
              </div>
              <ButtonIcon
                icon={<Trash3 />}
                className="remove-button"
                destructive={true}
                onClick={handleRemoveFile}
              />
            </div>
          ) : (
            <div className="upload-box-container">
              <div
                className={`upload-box ${dragging ? 'dragging' : ''} ${submitCount > 0 && errors.imageUrl ? 'error' : ''}`}
                onClick={handleUploadAreaClick}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                <Upload className="upload-icon" />
                <div className="upload-text">Drag and Drop your Logo to Upload</div>
                <Button
                  variant="secondary"
                  size="small"
                  label="Choose an Image"
                  className="upload-button"
                />
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
                <div className="upload-instructions">
                  <div>Minimum size 100 x 100 pixels</div>
                </div>
              </div>
              <Input
                label="or Upload Logo from URL"
                name="imageUrl"
                className="image-url-input"
                placeholder="Paste an image URL"
                value={values.imageUrl}
                showErrorMessages={submitCount > 0 && errors.imageUrl ? true : false}
                errorMessage={submitCount > 0 && errors.imageUrl}
                onChange={handleImageUrlChange}
              />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default PluginMarketplaceSettings;
