import { isUserSystemAdmin } from 'app/pages/Users/utils';
import { createSelector } from 'reselect';

const userSelector = state => state.user;

export const userAuthSelector = createSelector([userSelector], userView => userView.auth);

export const permissionsByResourceSelector = createSelector([userSelector, (_, resource) => resource], (userView, resource) => (userView.permissions && userView.permissions[resource]?.map(permission => permission.name)) || []);

export const currentUserSelector = createSelector([userSelector], userView => userView.currentUser?.data);

export const usersDataSelector = createSelector([userSelector], userView => userView.users.data);
export const usersLoadingSelector = createSelector([userSelector], userView => userView.users.loading);
export const usersErrorsSelector = createSelector([userSelector], userView => userView.users.error);

export const userDetailsSelector = createSelector([userSelector], userView => userView.user.data);
export const userDetailsLoadingSelector = createSelector([userSelector], userView => userView.user.loading);
export const userDetailsErrorsSelector = createSelector([userSelector], userView => userView.user.errors);

// those are all the roles that system has...
export const rolesDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const rolesWithPermissionsDataSelector = createSelector([userSelector], userView => userView.roles?.data);
export const rolesWithPermissionsLoadingSelector = createSelector([userSelector], userView => userView.roles?.loading);
export const rolesWithPermissionsErrorsSelector = createSelector([userSelector], userView => userView.roles?.errors);

export const roleDetailsSelector = createSelector([userSelector], userView => userView.role?.data);
export const roleDetailsLoadingSelector = createSelector([userSelector], userView => userView.role?.loading);
export const roleDetailsErrorsSelector = createSelector([userSelector], userView => userView.role?.errors);

export const resourcesDataSelector = createSelector([userSelector], userView => userView.resources?.data);
export const resourcesLoadingSelector = createSelector([userSelector], userView => userView.resources?.loading);
export const resourcesErrorsSelector = createSelector([userSelector], userView => userView.resources?.errors);

export const searchRolesDataSelector = createSelector([userSelector], userView => userView.searchRoles?.data);
export const searchRolesLoadingSelector = createSelector([userSelector], userView => userView.searchRoles?.loading);
export const searchRolesErrorsSelector = createSelector([userSelector], userView => userView.searchRoles?.errors);

export const groupsSelector = createSelector([userSelector], userView => userView.groups?.items);
export const selectedGroupSelector = createSelector([userSelector], userView => userView.groups?.selected);

export const viewAsSelector = createSelector([userSelector], userView => userView.viewAs);

// selector to get subscriber dynamically
export const subscriberSelector = createSelector(
  [currentUserSelector, viewAsSelector],
  (currentUser, viewAs) => {
    const isAdmin = isUserSystemAdmin(currentUser);

    const currentUserEntityId = currentUser?.attributes?.entityId?.[0];
    const currentUserEntity = currentUser?.attributes?.entity?.[0];
    const currentUserEntityType = currentUser?.attributes?.type?.[0];

    const entity = currentUserEntityType === 'parent' && currentUserEntity === 'merchant' ? 'parent-merchant' : currentUserEntity;

    //TODO: check if we need to extract role like this. Maybe it will get changed, atm it's harcoded
    if (viewAs) {
      return { id: viewAs.id, entity: viewAs.userType };
    } else if (isAdmin) {
      return { id: currentUser.id, entity: 'admin' };
    } else if (currentUserEntityId && currentUserEntity) {
      return { id: currentUserEntityId, entity };
    } else {
      return null;
    }
  }
);

export const userSchemaSelector = createSelector([userSelector], userView => userView.userSchema?.data);
export const userSchemaLoadingSelector = createSelector([userSelector], userView => userView.userSchema?.loading); 

export const userConfigurationSelector = createSelector([userSelector], userView => userView.userConfiguration?.data);
export const userConfigurationLoadingSelector = createSelector([userSelector], userView => userView.userConfiguration?.loading);

export const updateRoleLoadingSelector = createSelector([userSelector], userView => userView.role?.loading);
